export default class SubUploadBulkDataViewModel {
  public static createEmpty(): SubUploadBulkDataViewModel {
    return new SubUploadBulkDataViewModel();
  }
  constructor(
    public Description: string = '',
    public Instructions: {link: string; text: string}[] = [{link: '', text: ''}],
    public ButtonText: string = '',
  ) {}
}

