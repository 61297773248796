import ProgramImport from './ProgramImport';
import ImportPropertiesTypes from './ImportPropertiesType';
export class ProgramImportPreviewData {
  constructor(
    public PreviewId?: ImportPropertiesTypes,
    public FormattedStartDateTime = '',
    public FormattedEndDateTime = '',
    public Title: string = '',
    public Description: string = '',
    public Track: string = '',
    public Tags: string = '',
    public RoomLocation: string = '',
    public Groups: string = '',
  ) {}
  public GetDataToDisplayPreview(data: any) {
    return data.map((item: ProgramImport)=> new ProgramImportPreviewData(item.PreviewId,this.GetFormattedFullDate(item.StartDate,item.StartTime),this.GetFormattedFullDate(item.EndDate,item.EndTime),item.Title,item.Description,item.Track.trim(),item.Tags.trim(),item.RoomLocation,item.Groups.trim()));
  }
  private GetFormattedFullDate(date: Date | null, time: Date | null) {
    const formattedTime = time!== null ? time.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    }) : '';
    const formattedDate = date!== null ? date.toLocaleDateString([],{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC',
    }): '';
    return formattedTime!=='' && formattedDate!== '' ? formattedDate +', '+formattedTime : '';
  }
}
