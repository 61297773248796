
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { ProgramBulkOperations } from '@/entities/BulkOperations/Program/ProgramBulkOperations';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import ProgramAddInformation from '@/components/Program/AddInformation/ProgramAddInformation.vue';
import AddInformationWithEntity from '@/components/AddInformation/AddInformationWithEntity.vue';
import ProgramAssignTracks from '@/components/Program/BatchOperations/ProgramAssignTracks.vue';
import ImageProcess from '@/components/Common/ImageProcess.vue';
import ProgramEditTags from '@/components/Program/BatchOperations/ProgramEditTags.vue';
import ProgramBatchDateTime from '@/components/Program/BatchOperations/ProgramBatchDateTime.vue';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { ProgramTrackItem } from '@/entities/Program/ProgramTrackItem';
import SubModuleEnum from '@/enums/SubModuleEnum';
import { ProgramTagItem } from '@/entities/Program/ProgramTagItem';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import ModuleEnum from '@/enums/ModuleEnum';
import { FetchData, Root, Store } from '@/main';
import ApiContentType from '@/enums/ApiContentType';
@Component({
  components:
  { ImageProcess, ModalPopup,
    ProgramAddInformation,
    ProgramAssignTracks,
    ProgramEditTags,
    ProgramBatchDateTime,
    AddInformationWithEntity,
  } })
class ProgramBatchEditPane extends Vue {
  @Prop() private selectedItems?: ProgramItem[];
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private availableCustomCategoryItems?: CustomCategoryItem[];
  @Prop() private trackItems?: ProgramTrackItem[];
  @Prop() private availableTags?: ProgramTagItem[];
  private bulkEditOperations: ProgramBulkOperations = new ProgramBulkOperations(
    Root,
    Store,
    this.$t,
  );
  private signalR: ProgramSignalRModel = new ProgramSignalRModel(
    Root,
    Store,
    this.$t,
  );
  private selectedProgramIds: number[] = [];
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupHeading = '';
  private showSection_ModalPopup = '';
  private showEditTagsBatchPane = false;
  private showAssignToTracksBatchPane = false;
  private showAddInformationBatchPane = false;
  private showProgramBatchDateTimePane = false;
  private showProgramBatchImagesPane = false;
  private localAllInformations?: CustomCategoryItem[] = [];
  private localCustomCategoryFolders: CustomCategoryFolder[] = [];
  private isEnableFeaturedImage = false;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private fileDownload = require('js-file-download');
  private exportFileName = 'ProgramExport.xlsx';
  private selectedItemsIds: number[] = [];
  private entityType = ModuleEnum.Program;

  // read only properties
  private readonly ProgramModule = 'Program';
  private readonly BulkDeleteOperation = 'BulkDeleteOperation';
  private readonly BulkEditImageAndIconsOperation =
    'BulkEditImageAndIconsOperation';
  private missingImage: string = require('@/assets/Images/ImageMissing.png');
  private featureMissingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private UpdateBulkImages(selectedBulkOperationModel: string) {
    this.selectedProgramIds = this.signalR.GetSelectedProgramIdsToSendInJson(this.selectedItems!);
    this.localSelectedBulkOperationModel.SelectedIds = this.selectedProgramIds;
    this.localSelectedBulkOperationModel.JSONData = selectedBulkOperationModel;
    setTimeout(() => {
      Root.emit('show-notification-popup', true);
      Root.emit('show-notification-loading', true);
    }, 100);
    this.localSelectedBulkOperationModel.Heading = this.$t(
      this.localSelectedBulkOperationModel.Heading,
    ).toString();
    this.localSelectedBulkOperationModel.Description = this.$t(
      this.localSelectedBulkOperationModel.SubmitMessage,
      {
        selectedCount: this.selectedItems!.length,
        user: this.signalR.GetUserName(
          this.localSelectedBulkOperationModel,
          this.$t('UserName.You').toString(),
        ),
      },
    ).toString();
    this.signalR.PerformProgramBatchOperation(
      this.localSelectedBulkOperationModel,
    );
    Root.emit('program-uncheck-select-all');
    Root.emit('empty-track-tag');
  }

  private mounted() {
    const appInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
    this.isEnableFeaturedImage = !appInfo.EnableLinkProgramItemsToGroup;
    if (this.selectedItems !== null && this.selectedItems !== undefined) {
      this.selectedItems.forEach((element) => {
        this.selectedItemsIds.push(element.Id);
      });
    }
  }

  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  // Handle calling of Functions from Click Events in the List
  private handle_function_call(
    objModel: SignalRModelRequest,
    confirmationMessage: string,
    showConfirmationPopup: boolean,
  ) {
    this.selectedProgramIds = [];
    if (showConfirmationPopup) {
      this.selectedItems!.forEach((element) => {
        this.selectedProgramIds.push(element.Id);
      });
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.BulkDeleteOperation;
      this.modalPopupContent = this.$t(confirmationMessage, {
        selectedCount: this.selectedItems!.length,
      }).toString();
    } else {
      if (objModel.SubModule === SubModuleEnum.AssignToTrack) {
        this.ShowAssignToTracksBatchPane(true);
      } else if (objModel.SubModule === SubModuleEnum.LinkInformation) {
        this.ShowAddInformationBatchPane(true);
      } else if (objModel.SubModule === SubModuleEnum.EditTags) {
        this.ShowEditTagsBatchPane(true);
      } else if (objModel.SubModule === SubModuleEnum.EditDateAndTime) {
        this.ShowBatchDateTimePane(true);
      } else if (objModel.SubModule === SubModuleEnum.EditIconsAndImages) {
        this.ShowBatchImagesPane(true);
      } else if (objModel.SubModule === SubModuleEnum.ExportPrograms) {
        this.selectedItems!.forEach((element) => {
          this.selectedProgramIds.push(element.Id);
        });

        this.ExportProgramsExcel(this.selectedProgramIds);
      }
    }
    this.localSelectedBulkOperationModel = Object.assign({}, objModel);
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItemsIds.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.localSelectedBulkOperationModel.SelectedIds = this.selectedProgramIds;
      this.signalR.PerformProgramBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('program-uncheck-select-all');
      Root.emit('empty-track-tag');
    } else {
      return;
    }
  }
  private GetAddInformationComponentData() {
    this.localCustomCategoryFolders = this.availableCustomCategoryFolders || [];
    this.localAllInformations =  this.availableCustomCategoryItems || [];
  }
  private ShowAddInformationBatchPane(val: boolean) {
    this.showAddInformationBatchPane = val;
    if (val) {
      this.GetAddInformationComponentData();
    }
  }

  private ShowEditTagsBatchPane(val: boolean) {
    this.showEditTagsBatchPane = val;
  }
  private ShowAssignToTracksBatchPane(val: boolean) {
    this.showAssignToTracksBatchPane = val;
  }
  private showAttachInformation() {
    this.ShowAddInformationBatchPane(false);
  }
  private ShowBatchDateTimePane(val: boolean) {
    this.showProgramBatchDateTimePane = val;
  }
  private ShowBatchImagesPane(val: boolean) {
    this.showProgramBatchImagesPane = val;
  }

  private ExportProgramsExcel(val: number[]) {
    Root.emit('show-loader', true);
    FetchData('/Program/ExportProgramDataToExcel',JSON.stringify({
      programIds: val,
    }),ApiContentType.Excel)
      .then((response) => {
        Root.emit('show-loader', false);
        this.fileDownload(response, this.exportFileName);
        Root.emit('program-uncheck-select-all');
      })
      .catch(() => {
        Root.emit('show-loader', false);
        Root.emit('program-uncheck-select-all');
      });
  }
  @Watch('selectedItems')
  private onPropertyChanged() {
    this.selectedItemsIds = [];
    this.selectedItems!.forEach((element) => {
      this.selectedItemsIds.push(element.Id);
    });
  }
}
export default toNative(ProgramBatchEditPane);
