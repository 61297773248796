export class MultimediaItem {
  public static createEmpty(): MultimediaItem {
    return new MultimediaItem();
  }
  constructor(
    public Id: number = 0,
    public Title: string = '',
    public Url: string = '',
    public ThumbUrl: string = '',
    public Type: string = '',
    public ProgramId: number= 0,
    public IsImage: boolean = false,
    public IsVideo: boolean = false,
    // retain values for cancel operation
    public OldUrl: string= '',
    public OldThumbUrl: string= '',
    public OldTitle: string= '',
  ) { }
}
