/* eslint-disable max-len */
import { ProgramSessionQuestionOption } from './ProgramSessionQuestionOption';
import Common from '@/helper/Common';
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';

export class ProgramSessionQuestionsItem {
  public static createEmpty(): ProgramSessionQuestionsItem {
    return new ProgramSessionQuestionsItem();
  }

  public static GetSessionQuestionItemFromSessionQuestionItemSignalR(sItem: ProgramSessionQuestionsItem, sessionQuestion: ProgramSessionQuestionsItemSignalR): ProgramSessionQuestionsItem {
    sItem.id = sessionQuestion.id;
    sItem.questionTitle = sessionQuestion.questionTitle;
    sItem.typeId = sessionQuestion.typeId;
    sItem.SortOrder = sessionQuestion.SortOrder;
    sItem.ProgramResponseCount = sessionQuestion.ProgramResponseCount;
    sItem.status = sessionQuestion.status;
    sItem.ProgramQuestionType = sessionQuestion.ProgramQuestionType;
    sItem.globalProgramQuestion = sessionQuestion.globalProgramQuestion;
    sItem.allProgramIds = sessionQuestion.allProgramIds;
    sItem.attachedProgramIds = sessionQuestion.attachedProgramIds;
    sItem.programQuestionOptions = sessionQuestion.programQuestionOptions;
    sItem.ProgramQuestionResponseAccessKey = sessionQuestion.ProgramQuestionResponseAccessKey;
    return sItem;
  }
  constructor(
    public id: number = 0,
    public questionTitle: string = '',
    public typeId: number = 0,
    public isAnonymous: boolean = false,
    public isRequired: boolean = false,
    public isDeleted: boolean = false,
    public AddedDate: Date = new Date(),
    public AddedBy: string = '',
    public ModifiedDate: Date = new Date(),
    public ModifiedBy: string = '',
    public SortOrder: number = 0,
    public ProgramResponseCount: number = 0,
    public status: string = '',
    public allProgramIds: number[] = [],
    public attachedProgramIds: number[] = [],
    public defaultBGColor: string = '#fff',
    public defaultTextColor: string = '#333333',
    public defaultSubTextColor: string = '#8B8B8B',
    public ProgramQuestionType: number = 1,
    public programQuestionOptions: ProgramSessionQuestionOption[]= [],
    public ProgramQuestionResponseAccessKey: string= '',
    public globalProgramQuestion: boolean= false,
    public ApplicationId: number= 0,
    public ApplicationInstanceId: number= 0,
    public DisableItem: boolean= false,
  ) { }

  public SetProgramSessionQuestionObject(val: ProgramSessionQuestionsItem): any {
    return {
      questionTitle: val.questionTitle,
      typeId: val.typeId,
      isAnonymous: val.isAnonymous,
      SortOrder: val.SortOrder,
      attachedProgramIds: val.attachedProgramIds.sort(function(a, b) {
        return a - b;
      }),
      ProgramQuestionType: val.ProgramQuestionType,
      programQuestionOptions: val.programQuestionOptions,
      globalProgramQuestion: val.globalProgramQuestion,
    };
  }

  public CompareProgramObjects({ val1, val2 }: { val1: ProgramSessionQuestionsItem; val2: ProgramSessionQuestionsItem }): boolean {
    const programSessionQuestionItem1 = ProgramSessionQuestionsItem.prototype.SetProgramSessionQuestionObject(val1);
    const programSessionQuestionItem2 = ProgramSessionQuestionsItem.prototype.SetProgramSessionQuestionObject(val2);
    if (Common.prototype.CompareObjects(programSessionQuestionItem1, programSessionQuestionItem2)) {
      return true;
    } else {
      return false;
    }
  }
}

export class ProgramSessionQuestionsItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramSessionQuestionsItemSignalR {
    return new ProgramSessionQuestionsItemSignalR();
  }


  public static GetProgramSessionQuestionsItemSignalR(sessionQuestion: ProgramSessionQuestionsItem): ProgramSessionQuestionsItemSignalR {
    const item: ProgramSessionQuestionsItemSignalR = ProgramSessionQuestionsItemSignalR.createEmpty();
    item.id = sessionQuestion.id;
    item.questionTitle = sessionQuestion.questionTitle;
    item.typeId = sessionQuestion.typeId;
    item.SortOrder = sessionQuestion.SortOrder;
    item.ProgramResponseCount = sessionQuestion.ProgramResponseCount;
    item.status = sessionQuestion.status;
    item.ProgramQuestionType = sessionQuestion.ProgramQuestionType;
    item.globalProgramQuestion = sessionQuestion.globalProgramQuestion;
    item.allProgramIds = sessionQuestion.allProgramIds;
    item.attachedProgramIds = sessionQuestion.attachedProgramIds;
    item.programQuestionOptions = sessionQuestion.programQuestionOptions;
    item.ProgramQuestionResponseAccessKey = sessionQuestion.ProgramQuestionResponseAccessKey;
    item.selectedName = sessionQuestion.questionTitle;
    item.selectedCount = 1;
    return item;
  }

  constructor(
    public id: number = 0,
    public questionTitle: string = '',
    public typeId: number = 0,
    public SortOrder: number = 0,
    public ProgramResponseCount: number = 0,
    public status: string = '',
    public defaultBGColor: string = '#fff',
    public defaultTextColor: string = '#333333',
    public defaultSubTextColor: string = '#8B8B8B',
    public ProgramQuestionType: number = 1,
    public globalProgramQuestion: boolean= false,
    public allProgramIds: number[] = [],
    public attachedProgramIds: number[] = [],
    public programQuestionOptions: ProgramSessionQuestionOption[]= [],
    public ProgramQuestionResponseAccessKey: string= '',
  ) {
    super(0, '');
  }


}
