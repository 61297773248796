import { ProgramEvaluationQuestionType } from './ProgramEvaluationQuestionType';
import { ProgramEvaluationQuestionsItem } from './ProgramEvaluationQuestionsItem';

export class ProgramEvaluationQuestionsViewModel {
  public static createEmpty(): ProgramEvaluationQuestionsViewModel {
    return new ProgramEvaluationQuestionsViewModel([], [], false);
  }
  constructor(
    public ProgramEvaluationQuestionDtos?: ProgramEvaluationQuestionsItem[],
    public ProgramEvaluationQuestionTypes?: ProgramEvaluationQuestionType[],
    public GlobalProgramEvaluation?: boolean,
  ) { }
}
