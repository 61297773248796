import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30c46645"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 1,
  class: "edit-section"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_ProgramTagItemEditPane = _resolveComponent("ProgramTagItemEditPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "header-content": _ctx.$t('Tag.DeleteTag'),
          "body-heading": _ctx.modalPopupHeading,
          "body-content": _ctx.modalPopupContent,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "header-content", "body-heading", "body-content", "show-close-button"]))
      : _createCommentVNode("", true),
    (_ctx.localTagItem && !_ctx.showEditTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getLeftDivStyle(_ctx.isMobileView))
          }, _toDisplayString(_ctx.localTagItem.TagName), 5),
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.getRightDivStyle(_ctx.isMobileView)),
            class: "right-container-btn"
          }, [
            (!_ctx.isMobileView)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: _normalizeClass(["btn btn-secondary valign", [_ctx.isMobileView ==true ? '' : 'btn-size-default']]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectItem(_ctx.localTagItem)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Edit")), 1)
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "btn btn-delete valign",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectItem(_ctx.localTagItem)))
                }, [
                  _createElementVNode("img", { src: _ctx.editIcon }, null, 8, _hoisted_3)
                ])),
            _createElementVNode("div", {
              class: "btn btn-delete valign",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.DeleteSelectedTag(_ctx.localTagItem)))
            }, [
              _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_4)
            ])
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localTagItem && _ctx.showEditTag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_ProgramTagItemEditPane, {
            "selected-tag-item": _ctx.localTagItem,
            "applicationborder-color": _ctx.loaderBorderColor,
            onProgramTagItemCancelOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.programTagItemCancelOperation($event))),
            onProgramTagItemEditOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.programTagItemEditedOperation()))
          }, null, 8, ["selected-tag-item", "applicationborder-color"])
        ]))
      : _createCommentVNode("", true)
  ]))
}