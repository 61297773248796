
import { Component, Vue, toNative, Prop, Watch } from 'vue-facing-decorator';
import { MapSupportingItem } from '@/entities/MapSupportingItem';

@Component
class MapPicker extends Vue {
  @Prop()
  private selectedItem?: MapSupportingItem;
  @Prop() private showHeading?: boolean;
  @Prop() private hideMap?: boolean;
  @Prop() private isReadOnly?: boolean;
  @Prop() private isStreetAddressRequired?: boolean;
  @Prop() private isCityRequired?: boolean;
  private IsShowMap = false;
  private localHideMap = false;
  private isMobileView = false;
  private mounted() {
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.localHideMap = this.hideMap !== null && this.hideMap !== undefined ? this.hideMap : false;
    this.renderInitialMap();
    this.showMap();
  }

  private get displayMap(): boolean {
    if (this.selectedItem) {
      if (this.selectedItem.Longitude !== 0 && this.selectedItem.Latitude !== 0) {
        return true;
      }
    }
    return true;
  }
  private set displayMap(val: boolean) {
    if (val === false) {
      if (this.selectedItem) {
        this.selectedItem.Latitude = 0;
        this.selectedItem.Longitude = 0;
      }
      this.IsShowMap = false;
    }
  }
  private displayMapClick(): any {
    if ((this.$refs.displayMapCheckbox as HTMLInputElement).checked) {
      if (this.selectedItem) {
        const geocoder = new google.maps.Geocoder();
        const geocodeRequest = { address: this.selectedItem.Address + ' ' +  this.selectedItem.City };
        geocoder.geocode(geocodeRequest, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (this.selectedItem) {
              this.IsShowMap = true;
              this.selectedItem.Latitude = results !== null ? results[0].geometry.location.lat() : 0;
              this.selectedItem.Longitude = results !== null ? results[0].geometry.location.lng() : 0;
              this.renderMap();
            }
          } else {
            this.IsShowMap = false;
            // Do we want to present error somehow?
          }
        });
      }
    }
  }
  private renderMap() {
    if (this.selectedItem) {
      const mapProperties = {
        center: new google.maps.LatLng(this.selectedItem.Latitude, this.selectedItem.Longitude),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false,
      };
      const mapInst = new google.maps.Map(this.$refs.mapcontainer as HTMLElement, mapProperties);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(this.selectedItem.Latitude, this.selectedItem.Longitude),
        map: mapInst,
      });
    }
  }
  private renderInitialMap() {
    if (this.selectedItem) {
      if (this.selectedItem.Latitude !== 0 && this.selectedItem.Longitude !== 0) {
        this.renderMap();
      }
    }
  }

  private showMap() {
    if (this.selectedItem) {
      if (this.selectedItem.Latitude !== 0 && this.selectedItem.Longitude !== 0) {
        this.IsShowMap = true;
        this.displayMap= true;
      } else {
        this.displayMap= false;
        this.IsShowMap = false;
      }
    }
  }

  @Watch('selectedItem')
  private onPropertyChanged() {
    this.renderInitialMap();
    this.showMap();
  }
}
export default toNative(MapPicker);
