import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ckeditor, {
      modelValue: _ctx.localText,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localText) = $event)),
      "read-only": _ctx.localIsReadOnly,
      config: _ctx.config
    }, null, 8, ["modelValue", "read-only", "config"])
  ]))
}