import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-255171c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-listing" }
const _hoisted_2 = {
  key: 0,
  class: "margin-top-15"
}
const _hoisted_3 = { class: "edit-section" }
const _hoisted_4 = { class: "tag-input-text" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  key: 0,
  class: "error-vuelidate"
}
const _hoisted_7 = { class: "submit-position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.selectedTagItem)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.initialTagItem.TagName.$model) = $event)),
                type: "text",
                class: _normalizeClass(["form-control width100", _ctx.status(_ctx.v$.initialTagItem.TagName)]),
                placeholder: _ctx.getPlaceholder(),
                maxlength: "70"
              }, null, 10, _hoisted_5), [
                [
                  _vModelText,
                  _ctx.v$.initialTagItem.TagName.$model,
                  void 0,
                  { trim: true }
                ]
              ]),
              (_ctx.v$.initialTagItem.TagName.$error)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("ProgramTag.Validation.NameRequired")), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-size-default",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Submit(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("SaveButton")), 1),
              _createElementVNode("button", {
                class: "btn btn-secondary btn-size-default margin-left-15",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Cancel(_ctx.v$)))
              }, _toDisplayString(_ctx.$t("CancelButton")), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}