/* eslint-disable max-len */
import { ProgramSignalRModel } from '@/signalRRequest/Program/ProgramSignalRModel';
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
export class ProgramBulkOperations extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    const storeHelper: StoreHelper = new StoreHelper($store);
    const appInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    const isEnableFeaturedImage = !appInfo.EnableLinkProgramItemsToGroup;
    const signalR: ProgramSignalRModel = new ProgramSignalRModel(rootObj, $store, $t);
    this.arrBulkOperations.push({ Id: 1, Name: 'Delete', IconUrl: require('@/assets/Images/delete-background-icon.svg'), IconPadding: 'padding:10px 17px 17px 17px;', Description: 'Permanently delete your selected items', ConfirmationMessage: 'Notifications.SureDeleteProgramItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedProgramSignalRModelRequest, ShowConfirmationPopup: true });

    if (isEnableFeaturedImage) {

      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons & Images', IconUrl: require('@/assets/Images/edit-icons.svg'), IconPadding: 'padding:10px 17px 17px 0px;', Description: 'Apply an icon or a featured image to selected items', ConfirmationMessage: 'Notifications.SureUpdateProgramItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedProgramIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    } else {

      this.arrBulkOperations.push({ Id: 2, Name: 'Edit Icons', IconUrl: require('@/assets/Images/edit-icons.svg'), IconPadding: 'padding:10px 17px 17px 0px;', Description: 'Apply an icon to selected items', ConfirmationMessage: 'Notifications.SureUpdateProgramItemsImage', ConfirmationMessageSubTitle: '', objModel: signalR.EditSelectedProgramIconsAndImagesSignalRModelRequest, ShowConfirmationPopup: false });
    }

    this.arrBulkOperations.push({ Id: 3, Name: 'Edit Date & Time', IconUrl: require('@/assets/Images/edit-date-time-icon.svg'), IconPadding: 'padding:10px 17px 17px 17px;', Description: 'Edit the date or time of your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkEditDateTimeSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 5, Name: 'Edit Tags', IconUrl: require('@/assets/Images/tag-background-icon.svg'), IconPadding: 'padding:10px 17px 17px 0px;', Description: 'Edit tags of your selected items for better organization', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkEditTagsSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 6, Name: 'Assign to Track', IconUrl: require('@/assets/Images/assign-track-icon.svg'), IconPadding: 'padding:10px 17px 17px 17px;', Description: 'Add or edit your selected items into tracks', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignTracksSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 7, Name: 'Link Information', IconUrl: require('@/assets/Images/link-icon.svg'), IconPadding: 'padding:10px 17px 17px 0px;', Description: 'Link information items to your selected items', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkLinkInformationSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 8, Name: 'Export', IconUrl: require('@/assets/Images/export-dialogue-icon.svg'), IconPadding: 'padding: 10px 17px 17px 17px;', Description: 'Exort all programs in one excel file', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.ExportProgramsSignalRModelRequest, ShowConfirmationPopup: false });
  }
}
