import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';

export class ProgramTagItem {
  public static createEmpty(): ProgramTagItem {
    return new ProgramTagItem(0, '');
  }

  public static GetTagItemFromTagItemSignalR(tItem: ProgramTagItem, tag: ProgramTagItemSignalR): ProgramTagItem {
    tItem.Id = tag.Id;
    tItem.TagName = tag.TagName;
    return tItem;
  }
  constructor(
    public Id: number,
    public TagName: string ) { }
}
export class ProgramTagItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): ProgramTagItemSignalR {
    return new ProgramTagItemSignalR();
  }

  public static GetProgramTagsItemSignalR(tag: ProgramTagItem): ProgramTagItemSignalR {
    const item: ProgramTagItemSignalR = ProgramTagItemSignalR.createEmpty();
    item.Id = tag.Id;
    item.TagName = tag.TagName;
    item.selectedName = tag.TagName;
    item.selectedCount = 1;
    return item;
  }
  constructor(
    public Id: number = 0,
    public TagName: string = '',
  ) {
    super(0, '');
  }
}
