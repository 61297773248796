
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { ParticipantGroups } from '@/entities/ParticipantGroups';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { Store, Root } from '@/main';
@Component
class ListOfParticipantGroups extends Vue {
  @Prop() private participantGroups?: ParticipantGroups[];
  @Prop() private subHeading?: string;
  @Prop() private displayGroupInfoInApp?: boolean;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private showInformation = false;
  private showNotAvailableInformation = false;
  private isGroupsAvailable = false;
  private hasSelectedGroups = false;
  private selectedParticipantGroups: ParticipantGroups[] = [];
  private isEnabledSelectedGroupOption = false;
  private isEnableParticipantList = false;
  private localLinkedProgramLoading = false;
  private Groups: number[] = [];
  private localParticipantGroups: ParticipantGroups[] = [];
  private isGroupVisibilityTooltipVisible = false;
  private localDisplayGroupInfoInApp = false;

  private mounted() {
    // assign participantGroups in local variable
    if (this.participantGroups !== null) {
      this.localParticipantGroups = this.participantGroups!;
    }
    if(this.displayGroupInfoInApp !== null && this.displayGroupInfoInApp !== undefined) {
      this.localDisplayGroupInfoInApp = this.displayGroupInfoInApp;
    }
    // loader off from custom category end
    Root.on('customCategoryDetailLoading', (loading: boolean) => {
      this.localLinkedProgramLoading = loading ;
    });
    // loader off from prgram end
    Root.on('programDetailLoading', (loading: boolean) => {
      this.localLinkedProgramLoading = loading ;
    });
    // Check Enable participant on or off from setting
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    this.isEnableParticipantList = reqInfo.EnableParticipantList;
    const $this = this;
    Root.on('selected-participant-group-is', ( ParticipantGroupIds: number[]) => {
      // set selected groups
      $this.selectedParticipantGroups = [];
      $this.Groups = ParticipantGroupIds ;
      $this.GetGroupsData();
      if ($this.Groups !== undefined) {

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < $this.Groups.length; i++) {
          $this.selectedParticipantGroups.push($this.participantGroups!.find((record) => record.Id === $this.Groups[i])!);
        }
      }
      Root.emit('sendListOfParticipantGroupsInLocalItem', $this.selectedParticipantGroups);
    });
    if (this.participantGroups!.length > 0) {
      this.isEnabledSelectedGroupOption = true;
    }
  }
  private ShowGroupVisibilityCheckBox() {
    return this.displayGroupInfoInApp !== null && this.displayGroupInfoInApp !== undefined && this.hasSelectedGroups;
  }
  private ShowHideGroupVisibilityTooltip(val: boolean) {
    this.isGroupVisibilityTooltipVisible = val;
  }
  private SetGroupVisibilityCheckboxValue() {
    if(this.selectedParticipantGroups.length > 0) {
      this.localDisplayGroupInfoInApp = (this.$refs.chkGroupVisibility as HTMLInputElement).checked;
      this.$emit('set-group-visibility-value', this.localDisplayGroupInfoInApp);
    }
  }
  private setSelectedParticipantGroups(Groups: number[]) {
    // func use for set selected participant groups
    this.selectedParticipantGroups = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < Groups.length; i++) {
      this.selectedParticipantGroups.push(this.participantGroups!.find((record) => record.Id === Groups[i])!);
    }
    if (this.participantGroups!.length > 0) {
      this.isGroupsAvailable = true;
      this.isEnabledSelectedGroupOption = true;
    }
  }

  private getInformationString(val: boolean, type: string) {
    // for toolip information
    if (type === 'Not Available') {
      this.showNotAvailableInformation = val;
    } else {
      this.showInformation = val;
    }
  }

  private selectedOptionChange() {
    // func use when we change checkBox value
    // emit checkbox values acc.  to checkbox
    this.isGroupsAvailable = false;
    if (this.hasSelectedGroups === false) {
      // when user select everyone option empty the array
      this.selectedParticipantGroups = [];
      Root.emit('sendListOfParticipantGroupsInLocalItem', this.selectedParticipantGroups);
      this.$emit('sendListOfParticipantGroupsIneditpane', this.selectedParticipantGroups);

      // if everyone is selected then uncheck Display group information in app
      this.localDisplayGroupInfoInApp = false;
      this.$emit('set-group-visibility-value', this.localDisplayGroupInfoInApp);

    } else if (this.hasSelectedGroups === true) {
      this.isGroupsAvailable = true;
      Root.emit('sendListOfParticipantGroupsInLocalItem', this.selectedParticipantGroups);
      this.$emit('sendListOfParticipantGroupsIneditpane', this.selectedParticipantGroups);
    }
  }

  private GetGroupsData() {
    // show hide checkbox acc to data
    this.isGroupsAvailable = false;
    this.hasSelectedGroups = false;
    if (this.Groups !== null && this.Groups !== undefined && this.Groups.length > 0 && this.isEnableParticipantList  ) {
      this.isGroupsAvailable = true;
      this.hasSelectedGroups = true;
    }
  }
  private selectedParticipantGroupList(val: ParticipantGroups, index: number, event: any) {
    // checked or unchecked checkbox set data in this case
    const selectedParticipantGroup = event.target.checked;
    if (selectedParticipantGroup) {
      this.selectedParticipantGroups.push(val);
    } else {
      const objIndex = this.selectedParticipantGroups.findIndex(
        (obj: ParticipantGroups) => obj.Id === val.Id);
      if (objIndex > -1) {
        this.selectedParticipantGroups.splice(objIndex, 1);
      }
    }

    // if selectedParticipantGroups is equal to  0 then uncheck Display group information in app
    if (this.selectedParticipantGroups.length === 0) {
      this.localDisplayGroupInfoInApp = false;
      this.$emit('set-group-visibility-value', this.localDisplayGroupInfoInApp);
    }
    this.selectedOptionChange();
  }
  private isDisableCheckBox() {
    if (this.isEnabledSelectedGroupOption && this.isEnableParticipantList) {
      return true;
    } else {
      return false;
    }
  }
  @Watch('Groups')
  private onParticipantGroupsChange(val: number[]) {
    // switching between programs and set data acc. to new selected program data
    this.selectedParticipantGroups = [];
    this.GetGroupsData();
    if (val !== undefined && val !== null && val.length > 0) {
      this.setSelectedParticipantGroups(val);
    }
  }
}
export default toNative(ListOfParticipantGroups);
