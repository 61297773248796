
import { TabViewModel } from '@/entities/TabViewModel';
import { t } from '@/main';

export class ProgramTabList {
  public TabViewModels: TabViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.TabViewModels.push({ Id: 1, ComponentName: 'Program', Header: t('Program.ProgramTab').toString() ,IsDisabled: false});
    this.TabViewModels.push({ Id: 2, ComponentName: 'EvaluationQuestion', Header: t('Program.EvaluationsTab').toString(), IsDisabled: false });
    this.TabViewModels.push({ Id: 3, ComponentName: 'SessionQuestions', Header: t('Program.SessionQuestionsTab').toString(), IsDisabled: false });
  }
}
