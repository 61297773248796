import ProgramImport from './ProgramImport';
const ImportModuleType = {
  0: ProgramImport,
};
export type Keys = keyof typeof ImportModuleType;
  type ImportFiletypes = typeof ImportModuleType[Keys];
export type ExtractInstanceType<T> = T extends new () => infer R ? R : never;
export class ModuleTypeInstance {
  public static GetInstanceOfCurrentModule(K: Keys): ExtractInstanceType<ImportFiletypes> {
    return new ImportModuleType[K]();
  }
}
