export class ProgramEvaluationRecommendedQuestions {

  public static GetRecommendedQuestions(): ProgramEvaluationRecommendedQuestions[] {

    const arrProgramEvaluationRecommendedQuestions: ProgramEvaluationRecommendedQuestions[] = [];

    // eslint-disable-next-line max-len
    arrProgramEvaluationRecommendedQuestions.push({ Id: 1, QuestionName: 'General & Breakout Sessions', FontAwesomeIcon: 'fa fa-files-o fa-3x', QuestionDescription: 'Evaluate your session content,<br/>presenter quality and get <br/> additional feedback in 1 click' });
    return arrProgramEvaluationRecommendedQuestions;

  }
  public static createEmpty(): ProgramEvaluationRecommendedQuestions {
    return new ProgramEvaluationRecommendedQuestions();
  }
  constructor(
    public Id: number = 0,
    public QuestionName: string = '',
    public FontAwesomeIcon: string = '',
    public QuestionDescription: string = '',
  ) { }
}
