import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-margin-in-fields" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.$t("Program.SessionChat")), 1),
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        class: _normalizeClass([{'color-darkgray' : !_ctx.isCheckboxDisable}, "description"]),
        title: _ctx.GetTitle(),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetCheckboxValue()))
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "chkSessionChatVisibility",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localIsCheckboxChecked) = $event)),
          disabled: !_ctx.isCheckboxDisable,
          class: "checkbox-alignment",
          type: "checkbox"
        }, null, 8, _hoisted_3), [
          [_vModelCheckbox, _ctx.localIsCheckboxChecked]
        ]),
        _createTextVNode(_toDisplayString(_ctx.GetCheckboxLabelText()), 1)
      ], 10, _hoisted_2)
    ])
  ]))
}