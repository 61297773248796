import { ProgramSessionQuestionsItem } from './ProgramSessionQuestionsItem';
import { ProgramSessionQuestionType } from './ProgramSessionQuestionType';
export class ProgramSessionQuestionsViewModel {
  public static createEmpty(): ProgramSessionQuestionsViewModel {
    return new ProgramSessionQuestionsViewModel([], []);
  }
  constructor(
    public ProgramQuestionDtos?: ProgramSessionQuestionsItem[],
    public ProgramQuestionTypes?: ProgramSessionQuestionType[],
    public ProgramQuestionPublishedLink: string= '',
    public ProgramQuestionLinkExpirationDate: Date= new Date(),
  ) { }
}
