
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';

import {
  ProgramSessionQuestionsItem,
  ProgramSessionQuestionsItemSignalR,
} from '@/entities/Program/ProgramSessionQuestionsItem';
import { ProgramSessionQuestionsViewModel } from '@/entities/Program/ProgramSessionQuestionsViewModel';
import loading from '@/components/Common/loading.vue';
import Datepicker from '@/components/Common/Datepicker.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModuleEnum from '@/enums/ModuleEnum';
import SubModuleEnum from '@/enums/SubModuleEnum';
import EmitEnum from '@/enums/EmitEnums';
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import { StoreHelper } from '@/store/StoreHelper';
import { DateTimeOptions } from 'vue-i18n';
import { FetchData, Root, Store } from '@/main';
import CommonStyle from '@/helper/CommonStyle';
@Component({
  components: {
    loading,
    Datepicker,
    ModalPopup,
  },
})
class SessionQuestionsListView extends Vue {
  @Prop() private sessionQuestions?: ProgramSessionQuestionsItem[];
  @Prop() private selectAllItems?: boolean;
  @Prop() private existingSessionQuestionsCount?: number;
  @Prop()
  private editingProgramSessionQuestionItem?: ProgramSessionQuestionsItem;
  private emptySelectedItems = true;
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private modalPopupHeading = '';
  private modalPopupContentHeading = '';
  private modalPopupShowCancelButton = true;
  private modalPopupCancelButtonText = '';
  private modalPopupConfirmButtonText = '';
  private showLoading = false;
  private localSessionQuestions?: ProgramSessionQuestionsItem[] = [];
  private selectedItems: ProgramSessionQuestionsItem[] = [];
  private viewModel: ProgramSessionQuestionsViewModel = ProgramSessionQuestionsViewModel.createEmpty();
  private sessionQuestionDivBackgroundColor = '#fff';
  private selectedColor =CommonStyle.SelectedItemDivColor;
  private selectedItemTextColor = CommonStyle.SelectedItemHeadingColor;
  private whiteColor = '#FFFFFF';
  private temporarySelectedItems: ProgramSessionQuestionsItem[] = [];
  private ProgramQuestionPublishedLink = '';
  private editLinkExpirationDate = false;
  private TempProgramQuestionLinkExpirationDate: Date = new Date();
  private ProgramQuestionLinkExpirationDate: Date = new Date();
  private selectedProgramSessionQuestionItem: ProgramSessionQuestionsItem = ProgramSessionQuestionsItem.createEmpty();
  private showCopiedMessage?: boolean = false;
  private showSuccessMessage?: boolean = false;
  private linkCopiedFadeoutTime = 1500;
  private showSectionModalPopup = '';
  private selectedItemId = 0;
  private localSelectedSessionQuestionId = 0;

  // eslint-disable-next-line max-len
  private selectedProgramSessionQuestionsItemSignalR: ProgramSessionQuestionsItemSignalR = ProgramSessionQuestionsItemSignalR.createEmpty();
  private dateOptions: DateTimeOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private dateFormat = new Intl.DateTimeFormat();
  public loaderBorderColor = '';
  // constants
  private readonly Notification = 'Notification';
  private readonly NotificationUpdated = 'NotificationUpdated';
  private readonly NotificationBulkUpdated = 'NotificationBulkUpdated';
  private readonly NotificationNewProgramAdded = 'NotificationNewProgramAdded';
  private readonly NotificationProgramDeleted = 'NotificationProgramDeleted';
  private readonly WarningMessage = 'WarningMessage';
  private readonly SessionQuestionStatusAll = 'All';
  private starratingIcon = require('@/assets/Images/star-rating-icon.svg');
  private pollingIcon = require('@/assets/Images/polling-icon.svg');
  private textFeedbackIcon = require('@/assets/Images/text-icon.svg');

  private GetMoveToAnotherProgramMessage(): string {
    return this.$t('ChangeScreenMessage').toString();
  }
  // Mounted event of Component
  private mounted() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    this.dateFormat = new Intl.DateTimeFormat(
      browsersettings.locale,
      this.dateOptions,
    );
    if (this.sessionQuestions != null) {
      this.localSessionQuestions = this.sessionQuestions;
    }
    // Get Session questions from API
    this.showLoading = true;
    this.GetProgramSessionQuestionsViewModel();

    // Call root Level emits
    this.callrootlevelemits();
  }

  private UpdateAllProgramIdsInSessionQuestions(programId: number) {
    this.localSessionQuestions!.forEach((element) => {
      element.allProgramIds.push(programId);
      // when create new program and session question global true
      if (
        element.globalProgramQuestion &&
        element.allProgramIds.length !== element.attachedProgramIds.length
      ) {
        element.attachedProgramIds = Array.from(element.allProgramIds);
      } else if (
        element.allProgramIds.length !== element.attachedProgramIds.length &&
        element.status === this.SessionQuestionStatusAll
      ) {
        element.status =
          element.attachedProgramIds.length +
          this.$t('SessionQuestions.ProgramItem').toString();
      }
    });
  }


  // eslint-disable-next-line max-len
  private UpdateSelectedSessionQuestionsWhenDetachedfromProgram(programSessionQuestionItemId: number, notification: SignalRModelRequest) {

    if (this.selectedItems.length === 1 && programSessionQuestionItemId === this.selectedItems[0].id) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationNewProgramAdded;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t(
        'Button.Ok',
      ).toString();
      this.modalPopupCancelButtonText = this.$t(
        'CancelButton',
      ).toString();
      this.modalPopupHeading = this.$t('ItemUpdated').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {username: notification.UserName}).toString();
      this.localSelectedSessionQuestionId = this.selectedItems[0].id;
    } else  {
      this.showLoading = true;
      this.GetProgramSessionQuestionsViewModel();
    }
  }
  private UpdateAllProgramIdsInSessionQuestionsWithNewProgram(programId: number, notification: SignalRModelRequest) {
    this.localSessionQuestions!.forEach((element) => {
      element.allProgramIds.push(programId);
      // when create new program and session question global true
      if (element.globalProgramQuestion && element.allProgramIds.length !== element.attachedProgramIds.length) {
        if (this.selectedItems.length === 1 && element.id === this.selectedItems[0].id) {
          this.modalPopupVisible = true;
          this.showCloseButton = false;
          this.showSectionModalPopup = this.NotificationNewProgramAdded;
          this.modalPopupShowCancelButton = false;
          this.modalPopupConfirmButtonText = this.$t(
            'Button.Ok',
          ).toString();
          this.modalPopupCancelButtonText = this.$t(
            'CancelButton',
          ).toString();
          this.modalPopupHeading = this.$t('ItemUpdated').toString();
          this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
            username: notification.UserName,
          }).toString();
          this.localSelectedSessionQuestionId = this.selectedItems[0].id;
        }
        element.attachedProgramIds = Array.from(element.allProgramIds);
      } else if (element.allProgramIds.length !== element.attachedProgramIds.length &&
      element.status === this.SessionQuestionStatusAll) {
        element.status =  element.attachedProgramIds.length +
          this.$t('SessionQuestions.ProgramItem').toString();
      }
    });
  }
  private RemoveAttachedProgramIdsInSessionQuestions(programId: number) {
    this.localSessionQuestions!.forEach((element) => {
      if (element.status !== 'All') {
        const objIndex = element.attachedProgramIds.findIndex(
          (obj: number) => obj === programId,
        );
        element.attachedProgramIds.splice(objIndex, 1);
        element.status = element.attachedProgramIds.join(',');
      }
    });
  }


  // eslint-disable-next-line max-len
  private RemoveAttachedProgramIdsInSessionQuestionsWithNotification(programId: number , notification: SignalRModelRequest) {
    this.localSessionQuestions!.forEach((element) => {
      if (this.selectedItems.length === 1 && element.id === this.selectedItems[0].id) {
        this.modalPopupVisible = true;
        this.showCloseButton = false;
        this.showSectionModalPopup = this.NotificationProgramDeleted;
        this.modalPopupShowCancelButton = false;
        this.modalPopupConfirmButtonText = this.$t(
          'Button.Ok',
        ).toString();
        this.modalPopupCancelButtonText = this.$t(
          'CancelButton',
        ).toString();
        this.modalPopupHeading = this.$t('ItemUpdated').toString();
        this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
          username: notification.UserName,
        }).toString();
        this.localSelectedSessionQuestionId = this.selectedItems[0].id;
      } else {
        this.GetProgramSessionQuestionsViewModel();
      }
    });
  }
  // Get Session questions from API
  private GetProgramSessionQuestionsViewModel() {
    FetchData('/Program/GetProgramSessionQuestionsViewModel','')
      .then((response) => {
        const copyViewModel = response;
        this.viewModel = copyViewModel;
        this.localSessionQuestions = this.viewModel.ProgramQuestionDtos;
        this.showLoading = false;
        this.emitProgramQuestionTypeDtos();
        this.emitProgramQuestionPublishLinkAndExpirationDate();
        this.emitExistingSessionQuestionsCount();
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  private GetProgramSessionQuestionsViewModelAddingNewProgram() {
    FetchData('/Program/GetProgramSessionQuestionsViewModel','')
      .then((response) => {
        const copyViewModel = response;
        this.viewModel = copyViewModel;
        this.localSessionQuestions = this.viewModel.ProgramQuestionDtos;
        this.showLoading = false;
        this.emitProgramQuestionTypeDtos();
        this.emitProgramQuestionPublishLinkAndExpirationDate();
        this.emitExistingSessionQuestionsCount();

        this.selectedItems = [];
        const objIndex = this.localSessionQuestions!.findIndex(
          (obj: ProgramSessionQuestionsItem) => obj.id === this.localSelectedSessionQuestionId,
        );
        if (objIndex > -1) {
          const sessionQuestion = this.localSessionQuestions![objIndex];
          this.UpdateSelectedItem(sessionQuestion);
        }
      })
      .catch(() => {
        this.showLoading = false;
      });
  }

  // Emit ProgramQuestionTypes to Landing Page and Create New Question
  private emitProgramQuestionTypeDtos() {
    this.$emit('program-question-types', this.viewModel.ProgramQuestionTypes);
  }
  // Emit PublishedLink and Link Expiration Date for Program Questions
  private emitProgramQuestionPublishLinkAndExpirationDate() {
    this.ProgramQuestionPublishedLink = this.viewModel.ProgramQuestionPublishedLink;
    this.ProgramQuestionLinkExpirationDate = this.viewModel.ProgramQuestionLinkExpirationDate;
    this.TempProgramQuestionLinkExpirationDate = this.ProgramQuestionLinkExpirationDate;
  }
  // Set Expiration Date for Session Questions from Date picker component
  private SetProgramQuestionLinkExpirationDate(date: Date) {
    if (date != null && this.ProgramQuestionLinkExpirationDate != null) {
      this.ProgramQuestionLinkExpirationDate = date;
    }
  }
  // Show Textbox for editing Link Expiration Date os Session Questions
  private EditLinkExpirationDate() {
    this.editLinkExpirationDate = true;
    this.TempProgramQuestionLinkExpirationDate = this.ProgramQuestionLinkExpirationDate;
  }
  // Hide Link Expiration Date checkbox and reset value to existing
  private CancelLinkExpirationDate() {
    this.editLinkExpirationDate = false;
    this.ProgramQuestionLinkExpirationDate = this.TempProgramQuestionLinkExpirationDate;
  }

  private CloseLinkExpirationDate() {
    this.editLinkExpirationDate = false;
  }
  // Save Link Expiration Date of Session Questions
  private SaveLinkExpirationDate() {
    this.editLinkExpirationDate = false;
    const date = new Date(this.ProgramQuestionLinkExpirationDate);
    this.ProgramQuestionLinkExpirationDate = date;
    this.TempProgramQuestionLinkExpirationDate = this.ProgramQuestionLinkExpirationDate;
    this.showLoading = true;
    FetchData('/Program/UpdateExpireLinkDate', JSON.stringify({
      expireDate: this.ProgramQuestionLinkExpirationDate,
    }))
      .then((response) => {
        if (response.success === false) {
          alert(this.$t('Message.UnableToSave'));
        } else {
          // Successful update / save
          this.showSuccessMessage = true;
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, this.linkCopiedFadeoutTime);
        }
        this.showLoading = false;
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Format Link Expiration Date of Session Questions
  private formatLinkExpirationDate(expDate: Date): string {
    return this.dateFormat.format(expDate).toUpperCase();
  }

  private emitExistingSessionQuestionsCount() {
    const length = this.localSessionQuestions!.length;
    this.$emit('program-session-question-count', length);
  }
  private copyPublicLinkToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.ProgramQuestionPublishedLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.showCopiedMessage = true;
      } else {
        alert('Oops, unable to copy');
      }
    } catch (err) {
      alert('Oops, unable to copy');
    }
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
    document.body.removeChild(selBox);
  }
  // Call all root level emits
  private callrootlevelemits() {
    Root.on(
      'update-attached-programids-in-session-questions',
      (programId: number) => {
        this.RemoveAttachedProgramIdsInSessionQuestions(programId);
      },
    );
    Root.on(
      'update-attached-programids-in-session-questions-with-notification',
      (programId: number, notification: SignalRModelRequest) => {
        this.RemoveAttachedProgramIdsInSessionQuestionsWithNotification(programId, notification);
      },
    );
    Root.on(
      'update-attached-programids-in-session-questions-with-notification-new',
      () => {
        this.GetProgramSessionQuestionsViewModel();
      },
    );
    Root.on(
      'update-all-programids-in-session-questions',
      (programId: number) => {
        this.UpdateAllProgramIdsInSessionQuestions(programId);
      },
    );
    Root.on(
      'program-sessionQuestion-item-removed-with-notification-operation',
      (programSessionQuestionItemId: number, notification: SignalRModelRequest) => {
        this.UpdateSelectedSessionQuestionsWhenDetachedfromProgram(programSessionQuestionItemId, notification);
      },
    );
    Root.on(
      'update-all-programids-in-session-questions-with-new-Program',
      (programId: number, notification: SignalRModelRequest) => {
        this.UpdateAllProgramIdsInSessionQuestionsWithNewProgram(programId, notification);
      },
    );
    Root.on('update-session-question-listing', (value: boolean) => {
      if (value) {
        this.showLoading = true;
        this.GetProgramSessionQuestionsViewModel();
      }
    });
    Root.on(
      'program-sessionQuestion-item-cancel-operation',
      (newItem: ProgramSessionQuestionsItem) => {
        this.ClearAllCheckBoxes();
        this.resetSessionQuestionsBackgroundColor();
        newItem.defaultBGColor = this.sessionQuestionDivBackgroundColor;
        newItem.defaultTextColor = this.selectedItemTextColor;
        newItem.defaultSubTextColor = this.selectedItemTextColor;
        this.programSessionQuestionItemCancelledOperation(newItem);
      },
    );
    Root.on('clearSessionQuestionCheckboxes', () => {
      this.ClearAllCheckBoxes();
      this.resetSessionQuestionsBackgroundColor();
    });
    Root.on(
      'createEmptySessionQuestionItem',
      (newItem: ProgramSessionQuestionsItem) => {
        this.ClearAllCheckBoxes();
        this.selectedItems = [newItem];
        this.resetSessionQuestionsBackgroundColor();
      },
    );

    Root.on(
      'program-sessionquestion-item-delete-operation',
      (pqId: number) => {
        this.programSessionQuestionItemDeletedOperation(pqId);
      },
    );

    Root.on(
      'program-sessionquestion-item-edit-operation',
      (pq: ProgramSessionQuestionsItem) => {
        this.programSessionQuestionItemEditedOperation(pq);
      },
    );
    Root.on(
      'program-sessionquestions-bulk-item-deleted',
      (sessionQuestionIds: number[]) => {
        sessionQuestionIds.forEach((element) => {
          this.programSessionQuestionItemDeletedOperation(element);
        });
      },
    );

    Root.on(
      'program-sessionquestions-set-publishlink-expirationDate',
      (data: any) => {
        this.ProgramSessionQuestionSetPublishLinkAndExpirationDate(data);
      },
    );

    Root.on(
      'program-sessionquestions-batch-item-edit-operation',
      (pq: ProgramSessionQuestionsItem[]) => {
        pq.forEach((element) => {
          this.programSessionQuestionItemEditedOperation(element);
        });
        this.selectedItems = [];
      },
    );

    Root.on(
      'selected-sessionquestions-item-notification-completed',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          this.PerformOperationOnList(notification);
        }

        const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
          Root,
          Store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
      },
    );

    Root.on(
      'batch-selected-session-questions-assignSelectedIds',
      (notification: SignalRModelRequest) => {
        if (notification.IsSuccess) {
          if (this.selectedItems.length === 1) {
            const objIndex = notification.SelectedIds.findIndex(
              (obj: number) => obj === this.selectedItems[0].id,
            );
            if (objIndex > -1) {
              this.modalPopupVisible = true;
              this.showCloseButton = false;
              this.showSectionModalPopup = this.NotificationBulkUpdated;
              this.modalPopupShowCancelButton = false;
              this.modalPopupConfirmButtonText = this.$t(
                'Button.Ok',
              ).toString();
              this.modalPopupCancelButtonText = this.$t(
                'CancelButton',
              ).toString();
              this.modalPopupHeading = this.$t('ItemUpdated').toString();
              this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
                username: notification.UserName,
              }).toString();
            }
          } else {
            this.programSessionQuestionBulkAssignSelectedProgramIds(
              notification,
            );
          }
        }
        const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
          Root,
          Store,
          this.$t,
        );
        signalR.ProgramSignalRModelResponseReceived(notification);
      },
    );

    Root.on('reload-session-questions-data', () => {
      this.GetProgramSessionQuestionsViewModel();
    });
    Root.on(
      EmitEnum.disableProgramSessionQuestionsItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localSessionQuestions!.find(
            (x) => x.id === element,
          )!.DisableItem = true;
        });
      },
    );
    Root.on(
      EmitEnum.enableProgramSessionQuestionsItems,
      (selectedIds: number[]) => {
        selectedIds.forEach((element) => {
          this.localSessionQuestions!.find(
            (x) => x.id === element,
          )!.DisableItem = false;
        });
      },
    );
  }
  private ProgramSessionQuestionSetPublishLinkAndExpirationDate(data: any) {
    this.ProgramQuestionPublishedLink = data.Link;
    this.ProgramQuestionLinkExpirationDate = data.ExpirationDate;
    this.TempProgramQuestionLinkExpirationDate = data.ExpirationDate;
  }
  private PerformOperationOnList(notification: SignalRModelRequest) {
    if (
      notification.Module === ModuleEnum.ProgramSessionQuestions &&
      notification.SubModule === SubModuleEnum.SingleDelete
    ) {
      if (notification.SelectedIds.length > 0) {
        if (
          this.selectedItems.length === 1 &&
          this.selectedItems[0].id === notification.SelectedIds[0]
        ) {
          this.CheckIfDeletedItemIsOpenInEditMode(notification);
        } else {
          this.programSessionQuestionItemDeletedOperation(
            notification.SelectedIds[0],
          );
        }
      }
    } else {
      this.programSessionQuestionItemEditedOperationWithNotification(
        notification,
      );
    }
  }

  private CheckIfDeletedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
  ) {

    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    if (!signalR.IsLoggedInUserIsMe(notification)) {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.Notification;
      this.modalPopupShowCancelButton = false;
      this.modalPopupConfirmButtonText = this.$t('Button.Ok').toString();
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupHeading = this.$t('ItemDeleted').toString();
      this.modalPopupContent = this.$t('Message.ItemDeletedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedItemId = notification.SelectedIds[0];
    } else {
      this.programSessionQuestionItemDeletedOperation(
        notification.SelectedIds[0],
      );
    }
  }
  // Method to clear all chackboxes
  private ClearAllCheckBoxes() {
    this.selectedItems = [];
  }
  // Open Create new Question Pane
  private OpenCreateNewQuestionpane() {
    this.$emit('create-new-question-click', '');
  }

  // Get Attached Programs count for Listing
  private GetAttachedPrograms(pq: ProgramSessionQuestionsItem): string {
    let programs = '';
    if (pq.status !== '' && pq.status !== 'All') {
      if (pq.attachedProgramIds.length === 1) {
        programs =
          pq.attachedProgramIds.length +
          this.$t('SessionQuestions.ProgramItem').toString();
      } else if (pq.attachedProgramIds.length > 1) {
        programs =
          pq.attachedProgramIds.length +
          this.$t('SessionQuestions.ProgramItems').toString();
      }
    } else if (pq.status === '') {
      programs = this.$t('AddToPrograms').toString();
    } else if (pq.status === 'All') {
      programs = this.$t('SessionQuestions.AllProgramItems').toString();
    }
    return programs;
  }

  // Remove Session Question from Session Question Listing after it has been deleted
  private programSessionQuestionItemDeletedOperation(pqId: number) {
    const objIndex = this.localSessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === pqId,
    );
    if (objIndex > -1) {
      this.localSessionQuestions!.splice(objIndex, 1);
      this.selectedItems = [];
      this.emitExistingSessionQuestionsCount();
    }
  }

  // Update Session Question values in Session Question Listing after updates
  private programSessionQuestionItemEditedOperation(
    pq: ProgramSessionQuestionsItem,
  ) {
    const objIndex = this.localSessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === pq.id,
    );
    // New Session Question Added
    if (objIndex === -1) {
      this.localSessionQuestions!.push(pq);
      this.emitExistingSessionQuestionsCount();
    } else if (objIndex > -1) {
      if(this.localSessionQuestions !== null && this.localSessionQuestions !== undefined) {
        this.localSessionQuestions[objIndex] = pq;
      }
    }
    this.selectSessionQuestion(pq);
  }

  private programSessionQuestionBulkAssignSelectedProgramIds(
    notification: SignalRModelRequest,
  ) {
    const sessionQuestionIds: number[] = notification.SelectedIds;
    const result = JSON.parse(notification.JSONData);
    const isglobalProgramQuestion: boolean = result.globalProgramQuestion;
    const programIds: number[] = result.selectedProgramIds;
    sessionQuestionIds.forEach((element) => {
      const objIndex = this.localSessionQuestions!.findIndex(
        (obj: ProgramSessionQuestionsItem) => obj.id === element,
      );
      if (objIndex > -1) {
        const sessionItem: ProgramSessionQuestionsItem = this
          .localSessionQuestions![objIndex];
        sessionItem.attachedProgramIds = Array.from(programIds);
        sessionItem.globalProgramQuestion = isglobalProgramQuestion;
        if (programIds.length === 0) {
          sessionItem.status = '';
        } else if (programIds.length === sessionItem.allProgramIds.length) {
          sessionItem.status = 'All';
        } else {
          sessionItem.status = programIds.toString();
        }
        if(this.localSessionQuestions !== null && this.localSessionQuestions !== undefined) {
          this.localSessionQuestions[objIndex] = sessionItem;
        }
      }
    });
  }

  private ReloadConfirmedSessionQuestionItem() {
    const pq: ProgramSessionQuestionsItemSignalR = this
      .selectedProgramSessionQuestionsItemSignalR;
    const objIndex = this.localSessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === pq.id,
    );
    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );

    this.PerformSelectedItemUpdateOperation(
      this.SetSessionQuestionItemInList(objIndex, signalR, pq),
    );
  }
  private programSessionQuestionItemEditedOperationWithNotification(
    notification: SignalRModelRequest,
  ) {
    const pq: ProgramSessionQuestionsItemSignalR = JSON.parse(
      notification.JSONData,
    );
    const objIndex = this.localSessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === pq.id,
    );
    const signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
      Root,
      Store,
      this.$t,
    );
    // New Session Question Added
    if (objIndex === -1) {
      const pItem = signalR.FillProgramSessionQuestionObjectFromSignalRResponseNewItem(
        pq,
      );
      this.localSessionQuestions!.push(pItem);
      this.emitExistingSessionQuestionsCount();
      // scroll to bottom
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(this.localSessionQuestions!.length - 1);
      }
    } else if (objIndex > -1) {
      if (
        this.selectedItems.length === 1 &&
        this.selectedItems[0].id === pq.id
      ) {
        this.CheckIfUpdatedItemIsOpenInEditMode(
          notification,
          signalR,
          objIndex,
          pq,
        );
      } else {
        this.SetSessionQuestionItemInList(objIndex, signalR, pq);
      }

      // scroll to bottom
      if (signalR.IsLoggedInUserIsMe(notification)) {
        this.RedirectToItem(objIndex);
      }
    }
  }


  private CheckIfUpdatedItemIsOpenInEditMode(
    notification: SignalRModelRequest,
    signalR: ProgramSessionQuestionsSignalRModel,
    objIndex: number,
    pq: ProgramSessionQuestionsItemSignalR,
  ) {
    if (signalR.IsLoggedInUserIsMe(notification)) {
      this.PerformSelectedItemUpdateOperation(
        this.SetSessionQuestionItemInList(objIndex, signalR, pq),
      );
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = false;
      this.showSectionModalPopup = this.NotificationUpdated;
      this.modalPopupShowCancelButton = true;
      this.modalPopupConfirmButtonText = this.$t('Button.Reload').toString();
      this.modalPopupCancelButtonText = this.$t('Button.Ignore').toString();
      this.modalPopupHeading = this.$t('ItemUpdated').toString();
      this.modalPopupContent = this.$t('Message.ItemUpdatedBy', {
        username: notification.UserName,
      }).toString();
      this.selectedProgramSessionQuestionsItemSignalR = pq;
    }
  }
  private PerformSelectedItemUpdateOperation(
    pItem: ProgramSessionQuestionsItem,
  ) {
    this.selectedProgramSessionQuestionItem = pItem;
    this.selectedItems[0] = pItem;
    Root.emit('program-session-questions-updated', pItem);
  }

  private SetSessionQuestionItemInList(
    objIndex: number,
    signalR: ProgramSessionQuestionsSignalRModel,
    pq: ProgramSessionQuestionsItemSignalR,
  ): ProgramSessionQuestionsItem {
    const pItem = signalR.FillSessionQuestionObjectFromSignalRResponse(
      pq,
      objIndex,
      this.localSessionQuestions!,
    );
    if(this.localSessionQuestions !== null && this.localSessionQuestions !== undefined) {
      this.localSessionQuestions[objIndex] = pItem;
    }
    return pItem;
  }

  private programSessionQuestionItemCancelledOperation(
    pq: ProgramSessionQuestionsItem,
  ) {
    const objIndex = this.localSessionQuestions!.findIndex(
      (obj: ProgramSessionQuestionsItem) => obj.id === pq.id,
    );
    // Edited an existing Session Question
    if (objIndex > -1) {
      if(this.localSessionQuestions !== null && this.localSessionQuestions !== undefined) {
        this.localSessionQuestions[objIndex] = pq;
      }
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      if (this.showSectionModalPopup === this.WarningMessage) {
        this.UpdateSelectedItem(this.selectedProgramSessionQuestionItem);
      } else if (this.showSectionModalPopup === this.Notification) {
        this.programSessionQuestionItemDeletedOperation(this.selectedItemId);
      } else if (this.showSectionModalPopup === this.NotificationUpdated) {
        this.ReloadConfirmedSessionQuestionItem();
      } else if (this.showSectionModalPopup === this.NotificationBulkUpdated) {
        this.GetProgramSessionQuestionsViewModel();
        this.selectedItems = [];
      } else if (this.showSectionModalPopup === this.NotificationNewProgramAdded
      || this.showSectionModalPopup === this.NotificationProgramDeleted
      ) {
        this.GetProgramSessionQuestionsViewModelAddingNewProgram();
      }
    } else {
      return;
    }
  }
  @Watch('editingProgramSessionQuestionItem')

  private ChangeEditingProgramSessionQuestinItem(
    val: ProgramSessionQuestionsItem,
  ) {

    if (
      ProgramSessionQuestionsItem.prototype.CompareProgramObjects({
        val1: val,
        val2: this.selectedItems[this.selectedItems.length - 1],
      })
    ) {
      this.UpdateSelectedItem(this.selectedProgramSessionQuestionItem);
    } else {
      this.modalPopupVisible = true;
      this.showCloseButton = true;
      this.showSectionModalPopup = this.WarningMessage;
      this.modalPopupHeading = this.$t('UnsavedChanges').toString();
      this.modalPopupContent = this.$t('ChangeScreenMessage').toString();
      this.modalPopupShowCancelButton = true;
      this.modalPopupCancelButtonText = this.$t('CancelButton').toString();
      this.modalPopupConfirmButtonText = this.$t('YesButton').toString();
    }
  }
  // uncheck select all checkbox
  private UncheckSelectAllCheckbox() {
    this.$emit('uncheck-select-all-session-questions', false);
    this.emptySelectedItems = false;
  }
  private UpdateSelectedItem(
    selectedProgramSessionQuestion: ProgramSessionQuestionsItem,
  ) {
    this.UncheckSelectAllCheckbox();
    this.resetSessionQuestionsBackgroundColor();
    selectedProgramSessionQuestion.defaultBGColor = this.selectedColor;
    selectedProgramSessionQuestion.defaultTextColor = this.selectedItemTextColor;
    selectedProgramSessionQuestion.defaultSubTextColor = this.selectedItemTextColor;
    this.selectedItems = [];
    this.selectedItems.push(selectedProgramSessionQuestion);
  }
  // Select a session question in Session Question Listing and change the background color of the selected item
  private selectSessionQuestion(
    selectedProgramSessionQuestion: ProgramSessionQuestionsItem,
  ) {
    if (this.selectedItems.length === 1) {

      this.$emit('change-session-question-item', {
        selectedItem: selectedProgramSessionQuestion,
        moveToAnotherTab: false,
      });
      this.selectedProgramSessionQuestionItem = selectedProgramSessionQuestion;
    } else {
      this.UpdateSelectedItem(selectedProgramSessionQuestion);
    }
  }

  // Select session questions using checkboxes for Single / Batch operations
  private checkSessionQuestions(
    val: ProgramSessionQuestionsItem,
    index: number,
    event: any,
  ) {
    this.UncheckSelectAllCheckbox();
    setTimeout(() => {
      this.resetSessionQuestionsBackgroundColor();
    }, 100);
    setTimeout(() => {
      const programItemChecked = event.target.checked;
      if (!programItemChecked && this.selectedItems.length === 1) {
        this.temporarySelectedItems = this.selectedItems;
        this.temporarySelectedItems = this.temporarySelectedItems.filter(
          (x) => x.id !== val.id,
        );
        this.selectSessionQuestion(this.temporarySelectedItems[0]);
      } else if (this.selectedItems.length === 0) {
        this.selectSessionQuestion(val);
      }
    }, 100);
  }

  // Reset Background color for all session Questions listing
  private resetSessionQuestionsBackgroundColor() {
    if(this.localSessionQuestions !== null && this.localSessionQuestions !== undefined) {
      this.localSessionQuestions.forEach(function(item) {
        item.defaultBGColor = '#fff';
        item.defaultTextColor = '#3E3E3E';
        item.defaultSubTextColor = '#8B8B8B';
      });
    }
  }
  // Event that fires when selectedItems Property change
  @Watch('selectedItems')
  private onPropertyChange(
    val: ProgramSessionQuestionsItem[],
  ) {
    this.$emit('program-session-questions-selected', val);
    // emit property if diffirent session question item selected and scroll will go to top
    Root.emit('new-session-question-edit-item-pane', true);
    this.CancelLinkExpirationDate();
  }
  // Event fires when Select All checkbox is checked or unchecked
  @Watch('selectAllItems')
  private onPropertySelectAllItemsChange(val: boolean) {
    if (this.localSessionQuestions) {
      if (val === true) {
        this.selectedItems = [];
        this.localSessionQuestions.forEach((item) => {
          this.selectedItems.push(item);
        });
      } else {
        if (this.emptySelectedItems) {
          this.selectedItems = [];
        }
      }
      this.emptySelectedItems = true;
    }
  }
  private RedirectToItem(index: number) {
    setTimeout(() => {
      const element = document.querySelectorAll(
        '[indexSession="' + index + '"]',
      )[0];
      if (element) {
        element.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'end',
        });
      }
    }, 100);
  }

  private beforeUnmount() {
    Root.off('selected-sessionquestions-item-notification-completed');
    Root.off('program-sessionquestions-bulk-item-deleted');
    Root.off('batch-selected-session-questions-assignSelectedIds');
  }

}
export default toNative(SessionQuestionsListView);
