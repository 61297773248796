
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import {SessionQuestionBulkOperations} from '@/entities/BulkOperations/Program/SessionQuestionBulkOperations';
import { BaseBulkOperationsModel } from '../../../entities/BulkOperations/BaseBulkOperationsModel';
import { ProgramSessionQuestionsItem } from '@/entities/Program/ProgramSessionQuestionsItem';
import SessionQuestionsProgramListing from '@/components/Program/SessionQuestions/SessionQuestionsProgramListing.vue';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import loading from '@/components/Common/loading.vue';
import { ProgramSessionQuestionsSignalRModel } from '@/signalRRequest/Program/ProgramSessionQuestionsSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { Root, Store } from '@/main';
@Component({components: {
  SessionQuestionsProgramListing,
  ModalPopup,
  loading,
}})
class SessionQuestionBatchPane extends Vue {
  @Prop() private selectedItems?: ProgramSessionQuestionsItem[];
  @Prop() private programItems?: ProgramItem[];
  @Prop() private showLoading?: boolean;
  @Prop() private loaderBorderColor?: boolean;
  private bulkEditOperations: BaseBulkOperationsModel = new SessionQuestionBulkOperations(
    Root,
    Store,
    this.$t,
  );
  private signalR: ProgramSessionQuestionsSignalRModel = new ProgramSessionQuestionsSignalRModel(
    Root,
    Store,
    this.$t,
  );
  private selectedProgramIds: number[] = [];
  private showSessionQuestionListing = false;
  private modalPopupContent = '';
  private modalPopupContentHeading = '';
  private modalBodyContent = '';
  private modalPopupVisible = false;
  private showCloseButton = true;
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  // Handle calling of Functions from Click Events in the List
  private handle_function_call(
    objModel: SignalRModelRequest,
    confirmationMessage: string, subtitleConfirmationMessage: string, showConfirmationPopup: boolean,
  ) {
    if (showConfirmationPopup) {
      this.modalPopupVisible = true;
      this.modalPopupContent = this.$t(confirmationMessage).toString();
      this.modalBodyContent = this.$t(subtitleConfirmationMessage).toString();
      this.localSelectedBulkOperationModel = Object.assign({}, objModel);
    } else {
      this.AddToProgramScreen();
    }
  }
  private AddToProgramScreen() {
    this.showSessionQuestionListing = true;
  }
  private ShowBulkOperation(visibleBulk: boolean) {
    this.showSessionQuestionListing = visibleBulk;
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      const storeHelper: StoreHelper = new StoreHelper(Store);
      const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
      this.localSelectedBulkOperationModel.ApplicationId = Number(reqInfo.ApplicationId);
      this.localSelectedBulkOperationModel.EventId = Number(reqInfo.ApplicationInstanceId);
      const sessionQuestionIds: number[] = [];
      this.selectedItems!.forEach((element) => {
        sessionQuestionIds.push(element.id);
      });

      this.localSelectedBulkOperationModel.SelectedIds = sessionQuestionIds;
      this.signalR.PerformSessionQuestionBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      Root.emit('program-sessionquestion-tab-select-operation');
    } else {
      return;
    }
  }
}
export default toNative(SessionQuestionBatchPane);
