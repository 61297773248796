import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "top-margin-in-fields"
}
const _hoisted_2 = { class: "new-sub-section-heading margin-top-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isCheckboxVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("EventWebpage.EventWebpageVisibility")), 1),
        _createElementVNode("div", null, [
          _createElementVNode("label", {
            class: "description",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SetCheckboxValue()))
          }, [
            _withDirectives(_createElementVNode("input", {
              ref: "chkEventVisibility",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localIsCheckboxChecked) = $event)),
              class: "checkbox-alignment",
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, _ctx.localIsCheckboxChecked]
            ]),
            _createTextVNode(_toDisplayString(_ctx.GetCheckboxLabelText()), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}