
import { Root } from '@/main';
import { component as ckeditor } from '@mayasabha/ckeditor4-vue3';
import { Component, Prop, Vue, toNative, Watch } from 'vue-facing-decorator';
@Component({
  components: {
    ckeditor,
  },
})
class Editor extends Vue {
  @Prop() private description?: string;
  @Prop() private isReadOnly?: boolean;
  private localText?: string = '';
  private localIsReadOnly = false;
  private config: object = {
    allowedContent: 'b i u p a[href, target]',
    toolbar: [
      { name: 'basic', items: ['Bold', 'Italic', 'Underline'] },
      { name: 'links', items: ['Link', 'Unlink'] },
    ],
    height: 150,
  };

  private getInputTextValue() {
    this.localText = this.description;
  }

  private mounted() {
    this.getInputTextValue();
    this.localIsReadOnly = this.isReadOnly !== null && this.isReadOnly !== undefined ? this.isReadOnly : this.localIsReadOnly;
    // clear description box after submit the form.
    Root.on('clearDescription', (description: string) => {
      this.localText = description ;
    });
    // update description
    Root.on('UpdateEditorDescription', (description: string) => {
      this.localText = description ;
    });
  }
  @Watch('localText')
  private CheckChangeInLocalDescription(val: string) {
    this.$emit('updatedDescription', val);
  }
  @Watch('description')
  private CheckChangeInDescription(val: string) {
    this.localText = val;
  }
  @Watch('isReadOnly')
  private CheckChangeInReadOnly(val: boolean) {
    this.localIsReadOnly = val;
  }
}
export default toNative(Editor);
