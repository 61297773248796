import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab = _resolveComponent("tab")!
  const _component_tabs = _resolveComponent("tabs", true)!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_tabs, {
          ref: "testTabs",
          "cache-lifetime": "0",
          options: { useUrlFragment: false },
          onChanged: _ctx.handleTabChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTabViewModel, (item) => {
              return (_openBlock(), _createBlock(_component_tab, {
                id: item.Header,
                key: item.Id,
                "is-disabled": item.IsDisabled,
                name: item.Header
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.ComponentName)))
                ]),
                _: 2
              }, 1032, ["id", "is-disabled", "name"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onChanged"])
      ]))
    : _createCommentVNode("", true)
}