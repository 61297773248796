export class FontAwesomeViewModel {
  public static createEmpty(): FontAwesomeViewModel {
    return new FontAwesomeViewModel([], '', '', 0);
  }
  constructor(
    public FontAwesomeIcons: FontAwesomeIcon[],
    public SelectedBackgroundColor: string = '',
    public SelectedForegroundColor: string = '',
    public ApplicationId: number = 0) {
  }
}
export class FontAwesomeIcon {
  public static createEmpty(): FontAwesomeIcon {
    return new FontAwesomeIcon(0, 0);
  }
  constructor(
    public Id: number,
    public ApplicationId: number = 0,
    public IconName: string = '',
    public IconCode: string = '',
    public Module: string = '',
    public OffsetLeft: number = 0,
    public Offsettop: number = 0) {
  }
}
