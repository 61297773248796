import { VodDetail } from '@/entities/VodDetail';
export default class VodMuxVideoData {
  public static createEmpty(): VodMuxVideoData {
    return new VodMuxVideoData();
  }
  constructor(
    public VideoDetails: VodDetail = VodDetail.createEmpty(),
    public VirtualMeetingType: string = '',
    public Id: number = 0,
    public Title: string = '',
  ) {}
}
