export class SessionCheckInViewModel {
  public static createEmpty(): SessionCheckInViewModel {
    return new SessionCheckInViewModel();
  }
  constructor(
    public Id: number = 0,
    public IsSessionCheckInEnabled: boolean = false,
    public CheckedInParticipantsCount: number = 0,
    public MaxCheckInLimitQuantity: number = -1,
    public MinParticipantCheckInLimit: number = 0,
    public MaxParticipantCheckInLimit: number = 0,
  ) {}
}
