import { VodDetail } from './VodDetail';
export class VodEntityItemModel {
  public static createEmpty(): VodEntityItemModel {
    return new VodEntityItemModel();
  }
  constructor(
    public VirtualMeetingType: string = '',
    public Id: number = 0,
    public Title: string = '',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public VodDetail: VodDetail = VodDetail,
  ) { }
}
