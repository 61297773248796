import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4211d8ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline-flex100 align-items-center" }
const _hoisted_2 = { class: "main-title-width" }
const _hoisted_3 = { class: "main-heading primary-heading" }
const _hoisted_4 = { class: "margin-top-15" }
const _hoisted_5 = { class: "margin-top-15 description" }
const _hoisted_6 = { class: "table-border margin-top-15" }
const _hoisted_7 = { class: "width100 description" }
const _hoisted_8 = { class: "table-header-row" }
const _hoisted_9 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-size-default",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.HideImportPreview()))
        }, _toDisplayString(_ctx.$t("CancelButton")), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.localPreviewData.Heading), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-size-default",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Import()))
        }, _toDisplayString(_ctx.$t("Program.Import")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isValidationSummaryVisible)
        ? (_openBlock(), _createBlock(_component_ValidationSummary, {
            key: 0,
            "error-fields": _ctx.localPreviewData.ValidationErrors.message,
            onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
          }, null, 8, ["error-fields"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.localPreviewData.Description), 1),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("table", _hoisted_7, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localPreviewData.TableHadings, (heading, headingIndex) => {
              return (_openBlock(), _createElementBlock("th", {
                key: 'heading'+headingIndex,
                class: "table-header"
              }, _toDisplayString(heading), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localPreviewData.PreviewData, (data, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: _normalizeClass(["table-row", {'border-error': _ctx.ErrorInRow(index) }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (record, subIndex) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: index+''+subIndex,
                  class: _normalizeClass(["table-data", {'display-none': _ctx.DisplayPropertyInPreview(record)}])
                }, [
                  _createElementVNode("div", {
                    class: "line-limiter",
                    title: record
                  }, _toDisplayString(_ctx.GetTableData(_ctx.ShowValue(record))), 9, _hoisted_9)
                ], 2))
              }), 128))
            ], 2))
          }), 128))
        ])
      ])
    ])
  ]))
}