
export default class PreviewBulkDataViewModel {
  public static createEmpty(): PreviewBulkDataViewModel {
    return new PreviewBulkDataViewModel('','',[],[],[],{index: [],message: []},-1,-1);
  }
  constructor(
    public Heading: string,
    public Description: string,
    public PreviewData: any,
    public JsonData: any,
    public TableHadings: string[],
    public ValidationErrors: {index: number[]; message: string[]},
    public ModuleType: number,
    public SubModuleType: number,
  ) {}
}

