
import { Component, Vue, toNative, Prop } from 'vue-facing-decorator';
import { Root } from '@/main';
import {Tabs, Tab} from 'vue3-tabs-component';
import { TabViewModel } from '@/entities/TabViewModel';
@Component({
  components: {
    Tabs, Tab,
  },
})
class VueTabs extends Vue {
  @Prop() tabViewModel!: TabViewModel[];
  private localTabViewModel: TabViewModel[] = [];
  private isMounted = false;
  private mounted() {
    this.localTabViewModel = this.tabViewModel;
    this.isMounted = true;
    Root.on('select-previous-tab', (val: string) => {
      if(this.$refs.testTabs !== null && this.$refs.testTabs !== undefined) {
        (this.$refs.testTabs as any).selectTab('#'+ val);
      }
    });
  }
  private handleTabChange(tab: any){
    this.$emit('SelectedTab',tab);
  }
}
export default toNative(VueTabs);
