import { VodBaseRenderModel} from './VodBaseRenderModel';
import { CustomCategoryRenderer } from '../CustomCategory/CustomCategoryRenderer';
import { ProgramRenderer } from '@/entities/FactoryPattern/Program/ProgramRenderer';
import { VodDetail } from '@/entities/VodDetail';

// Interface to create base template
export interface Create {
  GetValues(vodDetail: VodDetail, entityId: number, sessionStartDate: Date,uploadUrl: string, acceptedVideoFileTypes: string, acceptedCaptionFileTypes: string, subModuleType: number): VodBaseRenderModel;
}
// Classes name for the Type
const ClassType = {
  0: CustomCategoryRenderer,
  1: ProgramRenderer,
};
// Create the keys name as per typeof Type
export type Keys = keyof typeof ClassType;
type ModuleTypes = typeof ClassType[Keys];
// Extract Instance Type
type ExtractInstanceType<T> = T extends new () => infer R ? R : never;
// Return the base class as per keys value
export class UserFactory {
  public static GetInstance(K: Keys): ExtractInstanceType<ModuleTypes> {
    return new ClassType[K]();
  }
}
