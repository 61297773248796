export class ProgramSessionQuestionOption {
  public static createEmpty(): ProgramSessionQuestionOption {
    return new ProgramSessionQuestionOption();
  }
  constructor(
    public id: number = 0,
    public optionName: string = '',
    public sortOrder: number = 0,
    public ProgramQuestionId: number= 0,
    public controlId: string = '',
    public IsDeleted: boolean = false,
    public defaultStyling: string= 'background-color:#ffffff;border-color:#ccc',

  ) { }
}
