import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f51259b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-top grid-listing" }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "right-container-margin" }
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = { class: "padding-top-20" }
const _hoisted_10 = { class: "margin-top-30" }
const _hoisted_11 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "margin-top-10" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "item-position-from-image valign-parent50 width70" }
const _hoisted_17 = { class: "valign" }
const _hoisted_18 = { class: "description-heading" }
const _hoisted_19 = { class: "valign-parent55 width30 text-right" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "btn btn-secondary btn-size-default",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseTrack()))
            }, _toDisplayString(_ctx.$t("Button.Close")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ProgramTrack.AssignTracks")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-size-default",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveTrack()))
            }, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.visibleValidationSummary)
            ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                key: 0,
                "error-fields": _ctx.validationErrorFields,
                onCloseClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.CloseValidationSummary($event)))
              }, null, 8, ["error-fields"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("ProgramTrack.SelectTrack")), 1),
        ((_ctx.localTrackItems==null || _ctx.localTrackItems.length==0))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", null, [
                _createElementVNode("i", {
                  class: "font-size-14",
                  innerHTML: _ctx.$t('ProgramTrack.EmptyScreenMessage')
                }, null, 8, _hoisted_12)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTrackItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.Id,
              class: "margin-top-15",
              style: {"display":"inline-flex","width":"100%"}
            }, [
              (item.IconUrl !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("img", {
                      src: _ctx.GetImageUrl(item.IconUrl),
                      class: "colorPicker"
                    }, null, 8, _hoisted_15)
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    style: _normalizeStyle(_ctx.GetColor(item.TrackColor)),
                    class: "colorPicker"
                  }, "    ", 4)),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(item.Name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                (item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-primary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.removeItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Selected")), 9, _hoisted_20))
                  : _createCommentVNode("", true),
                (!item.IsSelected)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-secondary btn-size-default valign",
                      onClick: ($event: any) => (_ctx.addItem(item))
                    }, _toDisplayString(_ctx.$t("Button.Select")), 9, _hoisted_21))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}