import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "programListViewPane",
  class: "scroll-top grid-listing"
}
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = {
  key: 0,
  class: "right-container-margin"
}
const _hoisted_4 = { class: "right-container-nav" }
const _hoisted_5 = { class: "width25" }
const _hoisted_6 = { class: "width50" }
const _hoisted_7 = { class: "main-heading primary-heading" }
const _hoisted_8 = { class: "add-btn-position" }
const _hoisted_9 = {
  key: 0,
  class: "empty-msg-position"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "margin-top-40" }
const _hoisted_12 = ["indexTrack"]
const _hoisted_13 = ["indexTrack"]
const _hoisted_14 = {
  key: 1,
  class: "right-container-margin margin-top-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgramTrackListViewEditItemPane = _resolveComponent("ProgramTrackListViewEditItemPane")!
  const _component_ProgramTrackItemEditPane = _resolveComponent("ProgramTrackItemEditPane")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.showImageProcess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: "btn btn-secondary btn-size-default",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseTrack()))
                }, _toDisplayString(_ctx.$t("Button.Close")), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ProgramTrack.ManageTracks")), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-size-default",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.AddTrack()))
                }, _toDisplayString(_ctx.$t("Button.Add")), 1)
              ])
            ]),
            ((_ctx.trackItems==null || _ctx.trackItems.length==0) && _ctx.localTrackItem==null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", null, [
                    _createElementVNode("i", {
                      class: "font-size-14",
                      innerHTML: _ctx.$t('ProgramTrack.EmptyScreenMessage')
                    }, null, 8, _hoisted_10)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackItems, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.Id,
                  indexTrack: index,
                  class: "margin-top-15"
                }, [
                  _createVNode(_component_ProgramTrackListViewEditItemPane, {
                    "selected-edit-track-item": item,
                    "applicationborder-color": _ctx.loaderBorderColor,
                    onProgramTrackItemEditOperation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setCurrentlocalItem($event))),
                    onProgramTrackItemImageUploadOperation: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickOnImage($event))),
                    onProgramTrackItemImageUploadCompleted: _cache[4] || (_cache[4] = ($event: any) => (_ctx.programTrackItemImageUploadCompleted($event)))
                  }, null, 8, ["selected-edit-track-item", "applicationborder-color"])
                ], 8, _hoisted_12))
              }), 128))
            ]),
            _createElementVNode("div", {
              id: "programTrackItemEditPane",
              indexTrack: _ctx.trackItems.length
            }, [
              (_ctx.localTrackItem)
                ? (_openBlock(), _createBlock(_component_ProgramTrackItemEditPane, {
                    key: 0,
                    "selected-track-item": _ctx.localTrackItem,
                    "applicationborder-color": _ctx.loaderBorderColor,
                    onProgramTrackItemCancelOperation: _cache[5] || (_cache[5] = ($event: any) => (_ctx.programTrackItemCancelOperation($event))),
                    onProgramTrackItemImageUploadOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clickOnImage($event)))
                  }, null, 8, ["selected-track-item", "applicationborder-color"]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_13)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showImageProcess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_ImageProcess, {
              "image-src": _ctx.GetImageUrl(),
              "image-full-src": _ctx.GetImageUploadUrl(),
              "heading-title": _ctx.$t('ImageProcess.iconImage'),
              onSetImageIconUrl: _ctx.SetImageIconUrl,
              onSelectBack: _ctx.clickOnImage,
              onGetPreviousIconUrl: _ctx.GetPreviousIconUrl
            }, null, 8, ["image-src", "image-full-src", "heading-title", "onSetImageIconUrl", "onSelectBack", "onGetPreviousIconUrl"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}