export default class ActionButtonViewModel {
  public static createEmpty(): ActionButtonViewModel {
    return new ActionButtonViewModel();
  }
  constructor(
    public Heading: string = '',
    public Description: string = '',
    public ButtonText: string = '',
    public ButtonAction: number = 0,
  ) {}
}
