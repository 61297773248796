/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { ProgramTrackItem } from './ProgramTrackItem';
import { ProgramTagItem } from './ProgramTagItem';
import { ParticipantGroups } from '../ParticipantGroups';
import { CustomCategoryFolder } from '../CustomCategory/CustomCategoryFolder';
import { CustomCategoryItem } from '../CustomCategory/CustomCategoryItem';

export class ProgramViewModel {
  public static empty() {
    return new ProgramViewModel([], [], [], [], [], [], false);
  }

  constructor(public ProgramList: ProgramItem[], public ProgramTracks: ProgramTrackItem[], public ProgramTags: ProgramTagItem[],
              public ParticipantGroups: ParticipantGroups[] , public CustomCategoryFolders: CustomCategoryFolder[],
              public CustomCategoryItems: CustomCategoryItem[], public HasRTMPStreamings: boolean) {}
}
